html {
  scroll-behavior: smooth;
  scrollbar-color: black transparent;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0 auto;
  font-family: pt sans;
  font-size: 17px;
  background: white;
  overflow-x: hidden;
}

.ElementsApp {
  font-size: 18px !important;
}

select {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
  padding: 20px 50px 20px 20px;
  text-transform: capitalize;
  font-size: 16px;
  position: relative;
  background-image: url('assets/icons/dropdown.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 15px;
  border-radius: 0px;
  background-color: white;
  background-position-x: 95%;
  margin: 3px 0px 15px -15px;
  margin-bottom: 20px !important;
  width: 100%;
  padding: 10px 5px;
  text-transform: capitalize;
  margin-left: 19px !important;
  border: 1px solid #a6a6a6;
}

option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
  padding: 20px 50px 20px 20px;
  text-transform: capitalize;
  font-size: 12px;
}

iframe {
  border: none !important;
  width: 100%;
  height: 100%;
  position: absolute;
}

section,
header {
  padding: 40px;
  margin: 0 auto;
  position: relative;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: pt sans;
}

h1 {
  font-size: 48px;
  font-weight: 400;
}

h2 {
  font-size: 21px;
  text-transform: uppercase;
  font-weight: 300;
}

h3 {
  font-size: 18px;
  font-weight: 300;
  margin-top: 0px;
  letter-spacing: 1px;
}

h5 {
  margin-bottom: 5px;
  font-weight: 300;
}

a {
  text-decoration: none;
  color: black;
}

p {
  margin: 10px 0px;
  font-size: 0.75em;
  text-transform: none;
}

.clear {
  display: table;
  clear: both;
}

.centre {
  margin: 0 auto;
  display: table;
  clear: both;
}

.right {
  float: right;
}

.cookie_header {
  display: none;
}

.freebirdFormviewerViewFormBanner,
freebirdHeaderMast {
  height: 170px !important;
}

.ReactModal__Body--open {
  overflow: hidden;
  /* opacity: 0.8; */
}

.ReactModal__Content {
  overflow-y: scroll;
  width: 98%;
  height: 98%;
  background: #f7f7f7;
  padding: 60px 0px 30px;
  box-sizing: border-box;
  margin: 0px;
  border: none !important;
  border-radius: 2px !important;
  padding-bottom: 0px !important;
  max-width: 1020px;
  position: fixed;
}
.ReactModal__Overlay {
  background: #000;
  z-index: 500;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-button :hover button {
  background: #ececec !important;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */

.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow: initial !important;
  min-height: 500px;
  background: white;
  box-shadow: #d0d0d0 0px 0px 2px;
}
/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

label {
  width: 100% !important;
}

/* Individual item */

.bm-item {
  display: inline-block;
}

/* Styling of overlay */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* FAQs */
.faq-style-wrapper {
  margin-top: 2em;
}

.faq-style-wrapper .faq-title {
  font-size: x-large;
  border-bottom: none;
}

.faq-title {
  margin: 0 auto 40px;
  width: 100%;
}

.faq-title,
.faq-row {
  border-bottom: none !important;
  max-width: 960px;
}

.faq-title > h2 {
  font-size: 26px;
  line-height: 50px;
  position: relative;
  z-index: 1;
  text-transform: capitalize;
  text-align: center;
  font-weight: 600;
  width: 100%;
}

.faq-style-wrapper .faq-body .faq-row {
  padding: 0.75em 0;
}

.faq-body {
  margin-bottom: 0px;
  text-align: left;
  padding: 0px;
  width: 100%;
}

.faq-row-wrapper {
  padding: 80px 0px 0px;
}

.row-title,
.row-content {
  /* background: #ededed; */
  padding: 0px 20px !important;
  border-left: 3px solid transparent;
}

.faq-row:nth-child(odd) {
  background: transparent;
}

.faq-style-wrapper .faq-body .faq-row .row-title {
  font-size: larger;
  color: grey;
}

.faq-style-wrapper .faq-body .faq-row .row-content-text {
  padding: 2em !important;
  font-size: medium !important;
  color: teal !important;
}

.row-option {
  margin-top: 2em;
}

.row-option label,
.row-title {
  color: #080913;
  font-weight: 600;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
}

.row-content {
  margin-bottom: 10px;
  line-height: 21px;
}

.row-content-text {
  width: 93%;
  margin-top: 5px;
}

.row-option input {
  padding: 5px;
  width: 2em;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.row-option button {
  padding: 0.5em 1em;
  margin-left: 1em;
  font-size: 1em;
  border-radius: 4px;
  background-color: #5000ca;
  border: 1px solid;
  color: white;
}

.MuiTimeline-root {
  padding: 6px 0px !important;
}

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
